import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Customers from '../components/Customers';
import Section, { ColumnsSection, SectionColumn } from '../components/Section';
import LinkButton from '../components/LinkButton';
import { MainTitle, SectionTitle }  from '../components/Titles';
import Activity from '../components/Activity';
import MainImageSection from '../components/MainImageSection';
import { Submit } from '../components/LinkButton';

import { colors } from '../shared/styles';

const InputSection = styled(ColumnsSection)`
  margin-top : 0rem;
  margin-bottom : 0rem;
`;

const MessageSection = styled(Section)`
  margin-top : 6rem;
  margin-bottom : 5rem;
`;

const ContactSection = styled(Section)`
  text-align : center;
  margin-bottom : 12rem;
`;

const Column = styled(SectionColumn)`
  font-size : 2.1rem;
  text-align : justify;
  margin : 6rem 3rem 3rem 3rem;
  line-height : 4rem;
  width : 100%;
`;

const Form = styled.form`
  margin-top : 8rem;
`;

const LargeColumn = styled(Column)`
  margin-left : 4rem;
  margin-right : 4rem;
`;


const ContactsLabel = styled.label`
  font-size : 3.0rem;
  text-transform : uppercase;
  display : block;
  font-family : TellMeMore;
`

const ContactsInput = styled.input`
  border : none;
  border-bottom : 1px solid black;
  background-color : transparent;
  font-size : 1.8rem;
  padding-top : 1rem;
  outline : none;
  width : 100%;
  font-size : 3.0rem;
  font-family : TellMeMore;
`

const ContactsMessage = styled.textarea`
  border : none;
  border-bottom : 1px solid black;
  background-color : transparent;
  font-size : 1.8rem;
  padding-top : 1rem;
  outline : none;
  width : 100%;
  margin-bottom : 6rem;
  height : 25rem;
  font-size : 3.0rem;
  font-family : TellMeMore;
  padding-top : 3rem;
  resize: none;
`

const Page = () => (
  <Layout>
    <SEO title="Contact" />
    <MainImageSection
      image={require('../images/contact_image.jpg')}
      mobileImage={require('../images/contact_image_mobile.jpg')}
      >
      {/*<MainTitle>Nous travaillons avec differents types d'acteurs</MainTitle>*/}
    </MainImageSection>
    <Form name="contact" method="POST" data-netlify="true" action="/message-sent">
      <input type="hidden" name="form-name" value="contact" />
      <InputSection>
        <LargeColumn>
          <ContactsLabel for="contact-nom">Nom*</ContactsLabel>
          <ContactsInput id="contact-nom" name="nom" type="text" />
        </LargeColumn>
        <LargeColumn>
          <ContactsLabel for="contact-prenom">Prenom*</ContactsLabel>
          <ContactsInput id="contact-prenom" name="prenom" type="text" />
        </LargeColumn>
      </InputSection>
      <InputSection>
        <LargeColumn>
          <ContactsLabel for="contact-email">Email*</ContactsLabel>
          <ContactsInput id="contact-email" name="email" type="text" />
        </LargeColumn>
        <LargeColumn>
          <ContactsLabel for="contact-sujet">Sujet</ContactsLabel>
          <ContactsInput id="contact-sujet" name="sujet" type="text" />
        </LargeColumn>
      </InputSection>
      <MessageSection>
        <ContactsLabel for="contact-message">Message</ContactsLabel>
        <ContactsMessage id="contact-message" name="message" required/>
      </MessageSection>
      <ContactSection>
        <Submit type="submit">Envoyer</Submit>
      </ContactSection>
    </Form>
  </Layout>
)

export default Page
